<template>
  <app-header
    :routes="routes"
  />
  <Main>
    <a-row :gutter="25">
      <a-col :xxl="24" :xl="24" :md="24" :xs="24">
        <div class="about-shipment-request-wrapper">
          <sd-cards :title="$t('layout.shipmentRequest.history')">
            <versions-table resourceName="shipment_request" :resourceId="shipmentRequestId"
            />
          </sd-cards>
        </div>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import {
  defineComponent, computed
} from 'vue';
import {useRouter} from 'vue-router';
import {Main} from '@/components/shared/styledComponents/Main';
import VersionsTable from '@/components/shipments/VersionsTable';

export default defineComponent({
  name: 'ShipmentRequestDetails',
  components: {
    Main, VersionsTable
  },
  setup() {
    const router = useRouter();
    const shipmentRequestId = computed(() => router.currentRoute.value.params.shipmentRequestId);

    const routes = computed(() => [
      {
        name: 'ClientShipmentRequests',
        breadcrumbName: 'layout.shipmentRequest.index'
      },
      {
        name: 'ClientShipmentRequestDetails',
        params: {shipmentRequestId: shipmentRequestId.value},
        breadcrumbName: 'views.shipmentRequest.details'
      },
      {
        name: 'ClientShipmentRequestHistory',
        params: {shipmentRequestId: shipmentRequestId.value},
        breadcrumbName: 'views.shipmentRequest.history'
      }
    ]);

    return {
      routes,
      shipmentRequestId
    };
  }
});
</script>
